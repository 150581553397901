<script>
</script>

<template>
    <div class="tagsBody">
        <img src="../assets/wallets.png" alt="">
        <div>{{$t('homePage.wel1')}} <br />{{$t('homePage.wel2')}}</div>
    </div>
</template>

<style  lang="scss" scoped>
    .tagsBody{
        height: calc(100vh - 160px);
        display: flex;flex-direction: column;align-items:center;justify-content: center;
        color: #6071F3;
        img{
            width: 300px;
        }
        div{
            font-size: 35px;
            text-align: center;
        }
    }
</style>
